.property-link {
  display: flex;
  align-items: flex-start;

  &.url {
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  .Link__button {
    display: none;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    font-size: 14.4px;
    margin-left: 8px;
    margin-top: 4px;

    &.table-cell {
      margin-top: 0;
    }

    &:hover {
      color: rgba(var(--center-channel-color-rgb), 0.72);
      background: rgba(var(--center-channel-color-rgb), 0.08);
    }

    &:active {
      color: var(--button-bg-rgb);
      background-color: rgb(var(--button-bg-rgb), 0.16);
    }
  }

  &:hover {
    .Link__button {
      display: flex;
    }
  }
}
