.focalboard-body {
  display: flex;
  flex-direction: column;

  &.full-page {
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }

  width: 100%;
  margin: 0;
  padding: 0;
  flex-grow: 1;

  * {
    outline: 0;
  }

  hr {
    width: 100%;
    height: 1px;
    border: 0;
    color: rgba(var(--center-channel-color-rgb), 0.09);
    background-color: rgba(var(--center-channel-color-rgb), 0.09);
    margin-bottom: 8px;
  }
}

/* Main app */

.dragover {
  background-color: rgba(128, 192, 255, 0.4);
}

.octo-spacer {
  flex: 1;
}

/* Icons */

.octo-icontitle {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}

/*-- Property list --*/

.octo-propertyvalue {
  &.empty {
    color: rgba(var(--center-channel-color-rgb), 0.4);
  }

  .IconButton.delete-value {
    width: 16px;
    min-width: 16px;
    height: 16px;

    i {
      font-size: 16px;
    }
  }
}

/*-- Editable --*/

.Editable {
  cursor: text;
}

.Editable.active {
  min-width: 100px;
}

.octo-propertyvalue.Editable.active,
.octo-table-cell .Editable.active {
  border-radius: 3px;
  box-shadow:
    rgba(15, 15, 15, 0.05) 0 0 0 1px,
    rgba(15, 15, 15, 0.1) 0 3px 6px,
    rgba(15, 15, 15, 0.2) 0 9px 24px;
}
