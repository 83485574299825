.Label {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  margin-right: 5px;
  border-radius: 3px;
  line-height: 20px;
  color: rgba(var(--center-channel-color-rgb), 0.8);
  white-space: nowrap;
  font-weight: 600;
  font-size: 13px;

  &.empty {
    color: rgba(var(--center-channel-color-rgb), 0.4);
    padding: 1px;
    text-transform: none;
    font-weight: normal;
  }

  input {
    line-height: 20px;
    color: rgba(var(--center-channel-color-rgb), 0.8);
    font-weight: 600;
    font-size: 13px;
  }
}
