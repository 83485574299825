.propColorDefault {
  background-color: var(--bg-default);
}

.propColorGray,
.propColorGray:hover {
  background-color: var(--bg-gray);
}

.propColorTurquoise,
.propColorTurquoise:hover {
  background-color: var(--bg-turquoise);
}

.propColorGreen,
.propColorGreen:hover {
  background-color: var(--bg-green);
}

.propColorOrange,
.propColorOrange:hover {
  background-color: var(--bg-orange);
}

.propColorYellow,
.propColorYellow:hover {
  background-color: var(--bg-yellow);
}

.propColorTeal,
.propColorTeal:hover {
  background-color: var(--bg-teal);
}

.propColorBlue,
.propColorBlue:hover {
  background-color: var(--bg-blue);
}

.propColorPurple,
.propColorPurple:hover {
  background-color: var(--bg-purple);
}

.propColorPink,
.propColorPink:hover {
  background-color: var(--bg-pink);
}

.propColorRed,
.propColorRed:hover {
  background-color: var(--bg-red);
}
