.Calculation {
  cursor: pointer;
  transition: opacity 0.1s ease-in;

  &.disabled {
    cursor: unset;
  }

  &.none {
    opacity: 0;

    .calculationLabel {
      text-transform: capitalize;
      letter-spacing: 0;
      font-size: 14px;
      margin-right: 0;
    }

    &.hovered {
      opacity: 0.64;
    }

    &.menuOpen {
      opacity: 1;
    }
  }

  .calculationLabel {
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.8;
    margin-right: 8px;
    letter-spacing: 0.8px;
  }

  .calculationValue {
    top: -1px;
    position: relative;
    font-weight: bold;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .CalculationOptions {
    right: 0;
    top: 0;
    position: absolute;
  }
}
