.IconButton {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;

  .Icon {
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
  }

  &--large {
    width: 40px;
    height: 40px;

    .Icon {
      width: 32px;
      height: 32px;
    }
  }

  &.margin-left {
    margin-left: 5px;
  }
}
