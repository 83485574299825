.TableRow,
.MuiTableCell-root {
  .open-button {
    display: none;

    .Button {
      padding: 2px 4px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      color: rgba(var(--center-channel-color-rgb), 0.64);
      background-color: rgba(var(--center-channel-color-rgb), 0.1);
      box-shadow:
        rgba(var(--center-channel-color-rgb), 0.1) 0 0 0 1px,
        rgba(var(--center-channel-color-rgb), 0.1) 0 2px 4px;
      height: 90%;
      width: 100%;
    }
  }

  &.selected {
    background-color: rgba(90, 200, 255, 0.2);
  }

  &:hover {
    .open-button {
      display: block;
    }
  }
}
