.Dialog {
  &.dialog-back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
  }

  .wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .dialog {
    display: flex;
    flex-direction: column;
    background-color: rgb(var(--center-channel-bg-rgb));
    box-shadow:
      rgba(var(--center-channel-color-rgb), 0.1) 0 0 0 1px,
      rgba(var(--center-channel-color-rgb), 0.1) 0 2px 4px;
    padding: 0;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    max-width: 100%;

    &.fullWidth {
      max-width: 1280px;
      width: 100%;
      height: 100%;
      height: 100%;
    }

    height: 100%;

    @media screen and (min-width: 975px) {
      right: unset;
      bottom: unset;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: 100%;
      margin: 0;
      height: calc(100% - 144px);
      min-width: 500px;
      max-width: 975px;
    }
  }
}
