.ViewHeader {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;

  border-bottom: solid 1px rgba(var(--center-channel-color-rgb), 0.16);
  margin: 0;
  padding-bottom: 4px;
  color: rgba(var(--center-channel-color-rgb), 0.64);
  align-items: center;

  #groupByLabel {
    margin-left: 0.3em;
  }

  @media (max-width: 768px) {
    &.view-header-rows-menu-visible {
      flex-direction: column;
    }
  }

  .IconButton {
    background: none;
    padding: 0;

    .Icon {
      width: 22px;
      height: 22px;
      margin: 0;
    }

    // &:hover {
    //   background: rgba(var(--center-channel-color-rgb), 0.1);
    // }
  }
}
