.bangle-tooltip {
  z-index: var(--z-index-modal);

  * {
    font-family: var(--font-family-default) !important; // override custom page font family
  }
}

.bangle-editor li[data-bangle-is-todo] > span:first-child > input {
  margin-top: 0.45rem;
}

.bangle-editor {
  border: none;
  outline: none;
}

/* Prevent iframes from stealing drag events - https://github.com/react-grid-layout/react-draggable/issues/613 */
.react-draggable-transparent-selection iframe {
  pointer-events: none;
}

.ProseMirror .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.ProseMirror-menubar-wrapper {
  border: 1px solid silver;
  outline: none;
}

/**
 * Bangle.div
 */
.bangle-nv-content {
  /** So that the element takes full width, else the browser does not
    show selection cursor.*/
  width: 100%;
}

/** Bangle-end*/

/**
 * Component-Styling
 */

.bangle-editor > p:first-child,
// .bangle-editor > h1:first-child,
// .bangle-editor > h2:first-child,
// .bangle-editor > h3:first-child,
.bangle-editor > h4:first-child,
.bangle-editor > h5:first-child,
.bangle-editor > h6:first-child,
.bangle-editor > blockquote:first-child,
.bangle-editor > pre:first-child,
.bangle-editor > hr:first-child,
.bangle-editor > figure:first-child,
.bangle-editor > dl:first-child,
.bangle-editor > dd:first-child,
.bangle-editor > ul:first-child,
.bangle-editor > ol:first-child {
  /** this prevents elements from bleeding out of the container */
  margin-top: 0;
}

.bangle-editor hr {
  margin: 0.5em 0;
}

// header styles copied from notion.so
.bangle-editor h1 {
  font-size: 1.875em;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 2rem;
  margin-bottom: 4px;
  max-width: 1040px;
}

.bangle-editor h2 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 1.4rem;
  margin-bottom: 1px;
  max-width: 1040px;
}

.bangle-editor h3 {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 1rem;
  margin-bottom: 1px;
  max-width: 1040px;
}

.bangle-editor h4 {
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 1.2rem;
}

.bangle-editor h5 {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}

.bangle-editor h6 {
  font-weight: 500;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.bangle-editor ul p,
.bangle-editor ol p,
.charm-column-row p {
  padding-bottom: 3px;
  padding-top: 3px;
}

.bangle-editor li {
  position: relative;

  li:first-child {
    padding-top: 0;
  }
}

// nested list styles
// ul li {
//   ul li {
//     list-style-type: circle;

//     ul li {
//       list-style-type: square;

//       ul li {
//         list-style-type: disc;
//       }
//     }
//   }
// }

// ol li {
//   ol li {
//     list-style-type: lower-alpha;

//     ol li {
//       list-style-type: lower-roman;

//       ol li {
//         list-style-type: circle;
//       }
//     }
//   }
// }

.bangle-editor li > span {
  display: block;
}

/* Make sure li selections wrap around markers */
.bangle-editor li.ProseMirror-selectednode {
  outline: none;
}

.bangle-editor li.ProseMirror-selectednode:after {
  content: '';
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}

.ProseMirror-selectednode {
  background-color: var(--charmeditor-active);
  transition: background-color 150ms ease-in-out;
}

#Prosemirror-hovered-page-node {
  background-color: var(--charmeditor-active);
  transition: background-color 150ms ease-in-out;
}

.bangle-nv-container {
  transition: background-color 150ms ease-in-out;
}

.bangle-editor > ol {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.bangle-editor p > code {
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  padding: 0.2em 0.4em;
  margin: 0 0.1em;
  font-size: 85%;
  border-radius: 6px;
}

.bangle-editor pre {
  white-space: pre-wrap;
  margin-top: 3px;
  margin-bottom: 3px;
}

/** Bullet List */
// .bangle-editor ul:not(.MuiList-root) {
//   list-style-type: disc;
//   list-style-position: outside;
//   padding-left: 24px;
// }

.bangle-editor ul,
.bangle-editor ol {
  margin-top: 0;
  margin-bottom: 0;
}

/** Disclosure component **/
.disclosure-details {
  padding-left: 20px;

  & > :not(:first-child) {
    display: none !important; /** Add !important for lists or other elements that set display **/
  }

  &[open] {
    & > summary::before {
      transform: rotate(0);
    }

    & > :not(:first-child) {
      display: block !important;
    }
  }

  summary {
    outline: 0 none;
    list-style: none;
    display: flex;
    margin-left: -22px;

    &::before {
      content: '▼';
      cursor: pointer;
      transform: rotate(-90deg);
      position: relative;
      width: 19px;
      top: 6px;
      height: 13px;
      margin-right: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.1s ease-in-out;
    }

    &::-webkit-details-marker,
    &::marker {
      color: var(--bg-gray);
      display: none;
    }

    & > * {
      display: inline;
    }
  }
}

/** Component-Styling-END */

/**
 * Prosemirror-Gap-Cursor
 */
/** From https://github.com/ProseMirror/prosemirror-gapcursor/blob/master/style/gapcursor.css */
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 2px solid var(--primary-text);
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

/** Prosemirror-Gap-Cursor-END */

/* This helps to position the page threads list */
.bangle-editor-core {
  width: 100%;
  position: relative;
}

// add some space at bottom of editor so user can click to adding new rows
.charm-empty-footer {
  width: 100%;
  height: 100px;
}

.empty-editor .row-handle {
  display: none;
}

// handles on the side of each row
.row-handle {
  display: initial;
  left: 0px;
  top: -1000px;
  position: absolute;
  width: 56px;
  height: 24px;
  margin-top: 3px;
  // opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.charm-drag-handle {
  display: flex;
  cursor: grab;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.bangle-editor-core {
  &:hover {
    .row-handle {
      opacity: 1;
    }
  }
}

.charm-column-row {
  @media (max-width: 600px) {
    flex-direction: column !important;

    .charm-column {
      flex: 1 1 auto !important;
    }
    .charm-column-resizer {
      display: none;
    }
  }
}

// for inline comments
.charm-row-decoration {
  background-color: var(--background-default);
  cursor: pointer;
  display: inline;
  position: absolute;
  right: -40px;
  // add a width so that icons are left-aligned even if counts get large
  width: 40px;

  @media (min-width: 600px) {
    right: -70px;
  }

  svg {
    color: rgba(var(--center-channel-color-rgb), 0.5);
  }

  .MuiTypography-root {
    color: rgba(var(--center-channel-color-rgb), 0.8);
    font-size: 0.8rem;
  }
}

/** placeholder - do not show while content is loading **/
.content-loaded {
  .ProseMirror[data-placeholder]::before,
  .charm-placeholder::before {
    display: block;
    height: 0;
    color: var(--secondary-text);
    content: attr(data-placeholder);
    opacity: 0.75;
    pointer-events: none;
    position: relative;
  }
  .ProseMirror[data-placeholder]::before {
    top: 3px;
  }
}

.readonly {
  .charm-placeholder::before {
    display: none;
  }
}

/** Begin Resizable columns **/

.charm-column {
  border-radius: 4px;
  padding: 8px 0;
  position: relative;
  overflow: hidden; // this is also set by the column-resizer library

  &::before {
    content: '';
    opacity: 0.4;
    transition: background-color 250ms ease-in-out;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 8px;
    z-index: -1; // make the background appear behind children
  }
  &:hover::before {
    background-color: var(--background-light);
  }

  & .bangle-nv-content p {
    overflow-wrap: anywhere;
  }
}

.charm-column-resizer {
  cursor: col-resize;
  position: relative;
  flex: 0 0 50px; //  this is the bar width, also set by the column-resizer library
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px; // extend outside boundary to give more space to detect the cursor
    right: -10px;
    > div {
      height: 100%;
      width: 4px;
      margin: 0 auto;
      padding: 4px 0;
    }
    &:hover > div {
      background-color: var(--input-border);
    }
  }
}

.read-only {
  .charm-column > .bangle-nv-container:hover {
    background-color: transparent;
  }
  .charm-column-resizer {
    visibility: hidden;
    pointer-events: none;
  }
}

.bangle-editor-core.readonly {
  p,
  h1,
  h2,
  h3,
  ul,
  ol,
  code,
  .tableScrollWrapper,
  .charm-column-row,
  .bangle-nv-container {
    &:has(div.charm-row-decoration-suggestions) {
      // important for column row, as it uses inline-styles
      display: none !important;
    }
  }

  // For NodeView blocks the decoration is adjacent to it
  .bangle-nv-container:has(+ div.charm-row-decoration-suggestions) {
    display: none;
  }

  div.charm-row-decoration-suggestions {
    display: none;
  }
}

/** End columns **/

/** Tab **/

/** hide tab when a user suggested to delete a list item - it should not exist yet **/
li .insertion > .charm-tab {
  display: none;
}

/** Copied from tooltip **/
.bangle-tooltip {
  display: none;
  z-index: 30;
}

.bangle-tooltip[data-show] {
  display: block;
}

.bangle-tooltip-arrow,
.bangle-tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.bangle-tooltip-arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #333;
}

.bangle-tooltip[data-popper-placement^='top'] > .bangle-tooltip-arrow {
  bottom: -4px;
}

.bangle-tooltip[data-popper-placement^='bottom'] > .bangle-tooltip-arrow {
  top: -4px;
}

.bangle-tooltip[data-popper-placement^='left'] > .bangle-tooltip-arrow {
  right: -4px;
}

.bangle-tooltip[data-popper-placement^='right'] > .bangle-tooltip-arrow {
  left: -4px;
}
