.PropertyMenu {
  &.menu-textbox {
    font-weight: 400;
    padding: 2px 10px;
    cursor: text;
    touch-action: none;
    border: solid 1px #909090;
    border-radius: 3px;
    margin: 5px;
  }
}
