.ViewTitle {
  input {
    background: transparent;
  }
  > .add-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 36px;
    color: rgba(var(--center-channel-color-rgb), 0.4);
    width: 100%;
    align-items: flex-start;

    .Icon:last-child {
      margin-left: 6px;
    }

    .Icon:first-child {
      margin-right: 6px;
    }

    .Button {
      min-height: 28px;
      display: none;
      line-height: 1;
    }

    &.add-visible:hover {
      .Button {
        display: flex;
      }
    }
  }

  .description {
    padding-left: 4px;
  }

  > .description > * {
    flex-grow: 1;
  }
}
