.Table {
  margin-top: 16px;
  padding-bottom: 0 !important;
  overflow: visible;
  position: relative;
  flex: 1;

  .octo-group-header-cell {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 50px;
    margin-right: 15px;
    margin-top: 15px;
    vertical-align: middle;

    &.narrow {
      width: 220px;
    }

    > div {
      margin-right: 8px;

      &:last-child {
        margin: 0;
      }
    }

    .IconButton {
      background-color: unset;

      &:hover:not(.readonly) {
        background-color: rgba(var(--center-channel-color-rgb), 0.1);
      }

      &.readonly {
        opacity: 0.5;
      }
    }

    .Label {
      display: inline-flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 3px;
      line-height: 20px;
      margin-right: 5px;
      color: rgba(var(--center-channel-color-rgb), 1);
      white-space: nowrap;
      text-transform: none;
      font-weight: normal;
      font-size: 16px;
      width: 100%;

      input {
        background: transparent;
        // width: 100%;
        text-transform: none;
        // font-weight: normal;
        // font-size: 16px;
        //     line-height: 20px;
        color: rgba(var(--center-channel-color-rgb), 1);
      }
    }

    > .Button {
      cursor: auto;

      &.IconButton:not(.readonly) {
        cursor: pointer;
      }
    }
  }

  .octo-table-row,
  .octo-table-cell {
    .icons {
      opacity: 0;
      position: absolute;
      left: -35px;

      &.row-actions {
        padding: 10px;
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }
    }
  }

  .octo-table-cell {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    color: rgb(var(--center-channel-color-rgb));
    border-right: solid 1px rgba(var(--center-channel-color-rgb), 0.09);
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
    padding: 8px;
    font-size: 14px;
    position: relative;
    text-overflow: ellipsis;

    .octo-icontitle {
      flex: 1 1 auto;

      .octo-icon {
        min-width: 20px;
        margin-top: 3px;
        font-size: 16px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }

      .Editable {
        flex: 1 1 auto;
      }
    }

    &.header-cell {
      padding-right: 0;
      cursor: pointer;

      .Label {
        display: flex;
        align-items: center;
      }

      .Icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
      }

      &:hover {
        background-color: rgba(var(--center-channel-color-rgb), 0.05);
      }
    }

    &:focus-within {
      background-color: rgba(46, 170, 220, 0.15);
      border: 1px solid rgba(46, 170, 220, 0.6);
    }

    .Editable {
      padding: 0 5px;
      left: -5px;
    }

    textarea.Editable {
    }

    .Editable.Editable.active {
      overflow: hidden;
    }

    .octo-propertyvalue {
      // line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;

      .Label {
        margin: 2px 5px;
        padding: 0 8px;
      }
    }

    .Editable,
    .octo-propertyvalue {
      text-align: left;
      width: inherit;
    }

    .DateRange.octo-propertyvalue {
      overflow: unset;
    }
  }

  .octo-table-body {
    display: flex;
    flex-direction: column;
  }

  .octo-table-header,
  .octo-table-row,
  .octo-table-footer {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px rgba(var(--center-channel-color-rgb), 0.09);

    &.hidden {
      display: none;
    }
  }

  .octo-table-header {
    position: sticky;
    top: -2px;
    z-index: 1;
    background: rgb(var(--center-channel-bg-rgb));

    .octo-table-cell {
      color: rgba(var(--center-channel-color-rgb), 0.6);
      background: rgb(var(--center-channel-bg-rgb));

      .Label {
        color: rgba(var(--center-channel-color-rgb), 0.6);
      }

      // styles for action column
      &:last-child {
        border: 0;
        padding: 0;

        .MenuWrapper {
          width: 100%;
        }

        .Button {
          border-radius: 0;
          width: 100%;
          height: 100%;
          justify-content: flex-start;
        }
      }
    }
  }

  .table-row-container {
    .octo-table-cell {
      align-items: flex-start;
    }
  }

  .octo-table-footer {
    .octo-table-cell {
      color: rgba(var(--center-channel-color-rgb), 0.6);
      cursor: pointer;
      width: 100%;
      padding-left: 15px;

      &:hover {
        background-color: rgba(var(--center-channel-color-rgb), 0.08);
      }
    }
  }

  .MenuWrapper {
    max-width: calc(100% - 5px);

    .Label {
      width: 100%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
