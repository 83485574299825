.CalendarContainer {
  margin-bottom: 10px;
  overflow: auto;

  --fc-border-color: var(--bg-gray);
  --fc-today-bg-color: rgba(var(--button-bg-rgb), 0.08);

  // make cursor a pointer when hovering cards
  .fc-event {
    cursor: pointer;
  }

  // fix extra spacing issues when embedded inline in a page
  .fc-daygrid-day-frame:before,
  .fc-daygrid-day-events:before,
  .fc-daygrid-event-harness:before,
  .fc-daygrid-day-frame:after,
  .fc-daygrid-day-events:after,
  .fc-daygrid-event-harness:after {
    font-size: 0;
  }

  // brighten the "Add event" button in dark mode
  .CalendarContainer .fc-day .dateContainer .addEvent {
    color: rgba(var(--center-channel-color-rgb), 0.8);
  }

  td,
  th {
    background-color: transparent;
  }

  // font size of month
  .fc .fc-toolbar-title {
    font-size: 1em;
  }

  a {
    text-decoration: none;

    &:hover {
      background-color: unset !important;
    }
  }

  .octo-tooltip {
    display: flex;
    max-width: 100%;
  }

  .octo-propertyvalue {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    input[value=''] {
      display: none;
    }

    .Label.empty {
      display: block;
      padding: 0;
      margin: 0;
    }
  }

  .octo-icontitle {
    flex: 1 1 auto;
    font-weight: 600;
    line-height: 20px;
    margin: 4px 0;

    .octo-icon {
      font-size: 16px;
      margin-right: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }
  }

  .fc {
    height: 100%;
  }

  .fc-day {
    .dateContainer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
      align-items: center;
      width: 100%;

      .addEvent {
        border-radius: 4px;
        margin: 0;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        display: none;
        font-size: 20px;
        box-shadow: var(--elevation-1);
        background: rgb(var(--center-channel-bg-rgb), 1);
        border: 1px solid rgb(var(--center-channel-color-rgb), 0.12);
        color: rgb(var(--center-channel-color-rgb), 0.56);
        cursor: pointer;

        &:hover {
          background: rgb(var(--center-channel-color-rgb), 0.08);
          color: rgb(var(--center-channel-color-rgb), 0.72);
        }

        &:active {
          color: rgba(var(--button-bg-rgb), 1);
          background: rgba(var(--button-bg-rgb), 0.08);
        }
      }
    }

    &:hover {
      .dateContainer {
        // display: flex;
        flex-direction: row;
        justify-content: space-between;

        .addEvent {
          display: flex;
        }
      }
    }
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-top: 0.75em;
    margin-bottom: 0.75em;

    .fc-button {
      background: rgba(var(--button-bg-rgb), 0.08);
      border-color: transparent;
      box-shadow: none !important;
      border-radius: 4px;
      color: rgb(var(--button-bg-rgb));
      margin: 2px;
      font-weight: 600;
      font-size: 14px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.fc-button-active {
        background-color: rgb(var(--button-bg-rgb));
        color: rgb(var(--button-color-rgb));
      }
    }

    .fc-today-button,
    .fc-prev-button,
    .fc-next-button {
      background-color: transparent;
      border: 0;
      color: rgb(var(--center-channel-color-rgb));

      &:hover {
        background-color: rgba(var(--center-channel-color-rgb), 0.1);
      }
    }

    .fc-prev-button,
    .fc-next-button {
      width: 32px;
      height: 32px;
      color: rgb(var(--center-channel-color-rgb), 0.56);

      &:hover {
        background: rgb(var(--center-channel-color-rgb), 0.08);
        color: rgba(var(--center-channel-color-rgb), 0.72);
      }

      &:active {
        background-color: rgba(var(--button-bg-rgb), 0.08);
        color: rgb(var(--button-bg-rgb), 1);
      }

      &:focus {
        border: 0;
      }
    }
  }

  // remove borders around header
  .fc-scrollgrid {
    border-left: 0 none;
    border-top: 0 none;
    .fc-scrollgrid-section-header > th {
      border: 0 none;
    }
  }

  // columns

  .fc-col-header-cell {
    background: transparent;
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
    color: var(--secondary-text);
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 4px;
  }

  .fc-daygrid-day-number {
    padding: 4px 8px !important;
  }

  .fc-daygrid-day-events {
    padding: 0 5px;
  }

  .fc-event {
    border: 1px solid;
    border-radius: 4px;
    border-color: rgba(var(--center-channel-color-rgb), 0.16);
    background-color: rgb(var(--center-channel-bg-rgb));
    box-shadow: var(--elevation-1);
    margin: 0 0 5px !important;
    overflow: hidden;
    padding: 4px 6px;

    &:hover::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(var(--center-channel-color-rgb), 0.1);
    }
  }

  .Label {
    font-size: 12px;
    padding: 2px 6px;
  }

  .fc-event-title {
    font-size: 14px;
    overflow: unset;
    white-space: normal;
  }

  .fc-daygrid-body {
    .fc-day-sat,
    .fc-day-sun {
      background-color: rgba(var(--center-channel-color-rgb), 0.04);
    }
  }

  .dateDisplay {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    min-width: 24px;
    height: 24px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    line-height: 23px;
  }

  .fc-dayGridMonth-view {
    .fc-day-today {
      background: transparent;

      .fc-daygrid-day-number {
        .dateDisplay {
          color: rgba(var(--button-color-rgb));
          background-color: rgba(var(--button-bg-rgb), 1);
        }
      }
    }
  }

  .fc-daygrid-day-number {
    align-items: center;
    width: 100%;
  }

  .fc-more-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 4px;
    color: rgba(var(--button-bg-rgb), 1);
    background-color: rgba(var(--button-bg-rgb), 0.08);
    font-weight: 600;
    margin-bottom: 8px;
    height: 24px;

    &:hover {
      background-color: rgba(var(--button-bg-rgb), 0.12);
    }

    &:active {
      background-color: rgba(var(--button-bg-rgb), 0.16);
    }
  }
}
