.markdown__table {
  margin: 5px 0 10px;
  background: var(--center-channel-bg);

  th,
  td {
    padding: 6px 13px;
    border: 1px solid #ddd;
  }

  a {
    white-space: nowrap;
    word-break: initial;
  }

  tbody tr {
    background: var(--center-channel-bg);
  }
}
