.GalleryCard {
  position: relative;
  border: 1px solid rgba(var(--center-channel-color-rgb), 0.09);
  border-radius: var(--default-rad);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    width: 280px;
    margin-right: 10px;
  }

  // HACK: Fixes Chrome drag and drop preview
  transform: translate3d(0, 0, 0);

  &.selected {
    background-color: rgba(90, 200, 255, 0.2);
  }

  // Disable hover on iOS so that cards popup on first click
  @media (pointer: fine) {
    &:hover {
      background-color: rgba(var(--center-channel-color-rgb), 0.05);

      .optionsMenu {
        display: block;
      }
    }
  }

  .optionsMenu {
    background-color: rgb(var(--center-channel-bg-rgb));
    border-radius: var(--default-rad);
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 30;

    .IconButton {
      background-color: rgba(var(--center-channel-color-rgb), 0.13);
    }
  }

  .gallery-item {
    background-color: rgba(var(--center-channel-color-rgb), 0.03);

    flex-grow: 1;
    overflow: hidden;
    padding: 0 10px;
    font-size: 0.7em;
    opacity: 0.7;
    max-height: 160px;
    min-height: 160px;
    pointer-events: none;

    .CheckboxElement {
      .Editable {
        background: transparent;
      }
    }
  }

  .gallery-image {
    flex-grow: 1;
    overflow: hidden;
    max-height: 160px;
    min-height: 160px;
    pointer-events: none;

    .ImageElement {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .gallery-title {
    flex-grow: 0;
    border-top: 1px solid rgba(var(--center-channel-color-rgb), 0.09);
    margin: 0;
    padding: 5px 10px;
    display: flex;
    overflow-wrap: anywhere;
    font-weight: 600;

    .octo-icon {
      margin-right: 5px;
    }
  }

  .gallery-props {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 0;
    margin: 0;
    padding: 5px 10px;
    overflow-wrap: anywhere;
    gap: 4px;

    .octo-icon {
      margin-right: 5px;
    }
  }

  .gallery-badges {
    padding: 5px 10px;
  }
}
