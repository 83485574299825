.Editable {
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  min-height: 24px;

  &.active {
    min-width: 100px;
  }

  &::placeholder {
    color: rgba(var(--center-channel-color-rgb), 0.4);
    opacity: 1;
  }

  &.error {
    border: 1px solid var(--danger-text);
    border-radius: var(--default-rad);
  }

  &.readonly {
    background-color: transparent;
    flex: 1;
  }
}
