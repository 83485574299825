.CardDetail {
  .title {
    width: 100%;
  }

  .add-buttons {
    display: flex;
    flex-direction: column;
    min-height: 32px;
    color: rgba(var(--center-channel-color-rgb), 0.4);
    align-items: flex-start;

    .Button {
      opacity: 0;
    }

    &:hover {
      .Button {
        opacity: 1;
      }
    }
  }

  .octo-propertylist {
    display: flex;
    flex-direction: column;
  }

  .octo-propertyrow {
    display: flex;
    align-items: flex-start;
    margin: 6px 0;
    min-height: 32px;

    .MenuWrapper {
      margin: 2px 0;
    }

    .Switch {
      margin-left: 8px;
      width: 40px;
      height: 24px;
      box-sizing: border-box;
      padding: 2px;

      .octo-switch-inner {
        width: 20px;
        height: 20px;
      }

      &.on {
        .octo-switch-inner {
          transform: translateX(16px) translateY(0);
        }
      }
    }

    .octo-propertyvalue {
      font-size: 14px;
      padding: 4px 8px;
      // height: 32px; commented out to allow for multi-line text

      &.octo-propertyvalue--readonly {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:not(.readonly) {
        min-width: 180px;
        transition: background 100ms ease-out 0s;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: var(--mui-action-hover);
        }
      }

      .MenuWrapper {
        display: flex;
        align-items: center;
      }
    }

    .Label {
      flex-shrink: 1;
      min-width: 0px;
      height: 22px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 120%;
      margin-right: 5px;
      margin-bottom: 5px;
      width: fit-content;
    }
  }

  .octo-propertyrow {
    .octo-propertyname {
      flex: 0 0 150px;
    }
  }

  &.content-blocks {
    &:hover,
    &:focus-within {
      .CardDetailContentsMenu {
        .Button {
          opacity: 1;
        }
      }
    }

    .CardDetailContentsMenu {
      margin-left: 48px;

      .Button {
        opacity: 0;
        color: rgba(var(--center-channel-color-rgb), 0.6);
      }
    }
  }
}

.octo-propertyname {
  font-weight: 600;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.octo-propertyname--readonly {
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  &.octo-propertyname--readonly .Button:hover {
    background: transparent;
    cursor: text;
  }

  width: 150px;
  margin-right: 5px;
  color: rgba(var(--center-channel-color-rgb), 0.6);

  .Button {
    width: 100%;
    padding: 4px 8px;
    text-align: left;
    justify-content: unset;
  }

  &.add-property {
    margin: 8px 0 0;
    color: rgba(var(--center-channel-color-rgb), 0.4);
  }
}
