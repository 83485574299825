.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.ProseMirror .column-resize-handle.for-margin-left {
  left: 0;
  right: auto;
}

.ProseMirror .tableWrapper {
  margin: 1em 0;
  td,
  th {
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    min-width: 1em;
    border: 1px solid var(--input-border);
    padding: 3px 5px;
  }
  th {
    font-weight: bold;
    text-align: left;
  }
}

@media only print {
  .ProseMirror table {
    width: 100% !important;
  }
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

/* Give selected cells a blue overlay */
.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
