.KanbanCalculationOptions_CustomOption {
  color: rgba(var(--center-channel-color-rgb), 1);
  min-height: 24px;
  max-width: 220px;
  width: 200px;
  padding: 2px 12px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: var(--charmeditor-active-hover);
  }

  span {
    overflow: hidden;
  }

  .drops {
    padding: 2px 14px;
    width: 200px;

    &.active,
    &.active:hover {
      background-color: var(--charmeditor-active);
      color: rgb(var(--sidebar-text-rgb));
    }
  }

  .customs:hover,
  .drops:hover {
    background: var(--charmeditor-active-hover);
  }

  .dropdown-submenu {
    position: fixed;
    overflow: auto;
    background: var(--background-paper);
    border: 0;
    box-shadow:
      0 0 0 1px hsla(0, 0%, 0%, 0.1),
      0 4px 11px hsla(0, 0%, 0%, 0.1);
    z-index: 999;
    padding: 6px 0;
    border-radius: 4px;
  }

  .CompassIcon.icon-chevron-right {
    float: right;
  }

  &.active {
    background-color: var(--charmeditor-active);

    > span {
      color: rgb(var(--sidebar-text-rgb));
    }
  }
}

.CalculationOptions__menu {
  .CalculationOptions__menu-list {
    max-height: 400px;
    padding: 8px 0;
  }
}
