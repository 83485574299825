.status-dropdown-menu {
  > .Menu {
    position: inherit;
  }
}

.Menu {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: var(--background-paper);
  color: rgb(var(--center-channel-color-rgb));
  border-radius: var(--default-rad);
  box-shadow: var(--elevation-4);
  max-height: 440px; // based off Notion's menu max-height

  .menu-contents {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    min-width: 240px;
  }

  .menu-options {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    position: relative;

    list-style: none;
    padding: 0;
    margin: 0;

    color: rgb(var(--center-channel-color-rgb));

    .CompassIcon {
      font-size: 18px;
      opacity: 0.56;
      width: 18px;

      &::before {
        margin: 0;
      }
    }

    > .menu-option {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      height: 32px;
      padding: 4px 20px;
      cursor: pointer;

      &.disabled-option {
        background: transparent !important;
        cursor: default;
      }

      // disable hover UX on ios which converts first click to a hover event
      @media (pointer: fine) {
        &:hover {
          background: rgba(var(--button-bg-rgb), 0.08);
        }
      }

      > * {
        margin-left: 16px;
      }

      > *:first-child {
        margin-left: 0;
      }

      > .menu-name {
        display: flex;
        flex-grow: 1;
        white-space: nowrap;
      }

      > .SubmenuTriangleIcon {
        fill: rgba(var(--center-channel-color-rgb), 0.7);
      }

      > .Icon {
        opacity: 0.56;
        width: 18px;
        height: 18px;
      }

      > .IconButton .Icon {
        margin-right: 0;
      }
    }

    > .menu-option.bold-menu-text {
      font-weight: bold;
    }
  }

  .menu-spacer {
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
  }

  @media not screen and (max-width: 430px) {
    &.top {
      bottom: 100%;
    }

    &.left {
      right: 0;
    }
  }
}

.Menu,
.SubMenuOption .SubMenu {
  @media screen and (max-width: 430px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 0;
    background-color: rgba(var(--center-channel-color-rgb), 0.5);
    border-radius: 0;
    padding: 10px;

    display: block;
    overflow-y: auto;

    .menu-contents {
      justify-content: flex-end;
      min-height: 100%;
    }

    .menu-options {
      align-items: center;
      border-radius: 10px;
      overflow: hidden;

      flex: 0 0 auto;

      > .menu-option {
        min-height: 44px;
        width: 100%;
        padding: 0 20px;
        background-color: rgb(var(--center-channel-bg-rgb));

        > * {
          flex: 0 0 auto;
        }

        > .noicon {
          width: 16px;
          height: 16px;
        }

        > .menu-name {
          font-size: 16px;
          justify-content: center;
        }
      }
    }
  }
}
