.ColorOption {
  .menu-colorbox {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    box-shadow: rgba(15, 15, 15, 0.1) 0 0 0 1px inset;
  }
}
