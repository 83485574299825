.CalculationRow {
  position: sticky;
  bottom: 0;
  height: 44px;
  border-top: solid 1px rgba(var(--body-color), 0.09);
  // To override table's specific border
  border-bottom: 0 !important;

  .octo-table-cell {
    align-items: center;
    justify-content: flex-end;
    border: 0 none !important;
  }

  .title {
    padding-left: 40px;
  }

  .CalculationOptions {
    width: 100%;
  }
}

.Table {
  .octo-table-cell.Calculation:focus-within {
    border: unset;
    background-color: rgba(var(--center-channel-color-rgb), 0.05);
  }

  .octo-table-cell.Calculation:hover {
    background-color: rgba(var(--center-channel-color-rgb), 0.05);
  }
}
