/* Make the Next.j wrappers 100% height */

html {
  height: 100%;
  width: 100%;
  display: table;
}

body {
  width: 100%;
  display: table-cell;
}

#__next {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

div[role='presentation'] {
  z-index: var(--z-index-modal);
}

.bangle-nv-container {
  position: relative;
}

blockquote .bangle-nv-child-container {
  width: 100%;
  display: flex;
  align-items: center;
}

// make sure tooltips always appear, on Account page it falls behind our custom modal
.MuiTooltip-popper {
  z-index: var(--z-index-tooltip) !important;
}

.telegram-login-CharmverseBot {
  width: 100%;
}

// fix for Autofill by Safari turning background yellow - https://stackoverflow.com/questions/41871618/how-to-change-safari-autofill-yellow-background-color
// Note: we use --bg-gray instead of --bg-input since it is an opaque color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px var(--bg-gray) inset !important;
  background-clip: content-box !important;
}

.not-selectable {
  user-select: none;
}

/* used in Charm Editor based on page.familyFont */
.font-family-serif {
  font-family: var(--font-family-serif);

  font-size: 17px;
  line-height: 1.6;

  &.font-size-small {
    font-size: 15px;
  }

  // override MUI typography
  .MuiInput-input,
  .MuiTypography-root,
  .MuiButton-root {
    font-family: var(--font-family-serif);
  }
}

.font-family-mono {
  font-family: var(--font-family-mono);

  font-size: 16px;
  tab-size: 2em;
  line-height: 1.6;

  &.font-size-small {
    font-size: 14px;
  }

  // override MUI typography
  .MuiInput-input,
  .MuiTypography-root,
  .MuiButton-root {
    font-family: var(--font-family-mono);
  }
}

.font-family-default {
  font-family: var(--font-family-default);

  font-size: 16px;
  line-height: 1.5;

  &.font-size-small {
    font-size: 14px;
  }

  // override MUI typography
  .MuiInput-input,
  .MuiTypography-root,
  .MuiButton-root {
    font-family: var(--font-family-default);
  }
}

body {
  /* wallet connect modal */
  --wcm-z-index: 1500;
}

// override MUI styles
.MuiFormLabel-asterisk {
  color: var(--danger-text);
}

// show red bordder on inputs that are disabled
.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--danger-text) !important;
  }
}
