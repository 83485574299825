.Switch {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: content-box;
  height: 14px;
  width: 26px;
  border-radius: 44px;
  padding: 2px;
  background-color: rgba(var(--center-channel-color-rgb), 0.24);
  transition:
    background 200ms ease 0s,
    box-shadow 200ms ease 0s;
  cursor: pointer;

  &.on:not(.disabled) {
    background-color: rgb(var(--button-bg-rgb));

    .octo-switch-inner {
      transform: translateX(12px) translateY(0);
    }
  }

  &.disabled {
    cursor: initial;
  }

  .octo-switch-inner {
    width: 14px;
    height: 14px;
    border-radius: 44px;
    background-color: rgb(var(--center-channel-bg-rgb));
    transition:
      transform 200ms ease-out 0s,
      background 200ms ease-out 0s;
    transform: translateX(0) translateY(0);
  }
}
