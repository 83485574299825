.KanbanCard {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-wrap: anywhere;

  border-radius: 3px;
  margin-bottom: 8px;
  padding: 12px 16px;
  box-shadow:
    rgb(15 15 15 / 10%) 0px 0px 0px 1px,
    rgb(15 15 15 / 10%) 0px 2px 4px;
  //0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  // box-shadow: rgba(var(--center-channel-color-rgb), 0.1) 0 0 0 1px,
  //   rgba(var(--center-channel-color-rgb), 0.1) 0 2px 4px;

  cursor: pointer;
  color: rgb(var(--center-channel-color-rgb));
  background-color: var(--background-paper);

  transition: background 100ms ease-out 0s;

  [data-theme='dark'] & {
    border-color: transparent;
  }

  // Disable hover on iOS so that cards popup on first click
  @media (pointer: fine) {
    &:hover {
      background-color: rgba(var(--center-channel-color-rgb), 0.1);

      .optionsMenu {
        display: block;
      }
    }
  }

  &.selected {
    background-color: rgba(90, 200, 255, 0.2);
  }

  .optionsMenu {
    display: none;
    position: absolute;
    right: 0;
  }

  .octo-tooltip {
    display: flex;
    max-width: 100%;
  }

  .octo-propertyvalue {
    input[value=''] {
      display: none;
    }

    .Label.empty {
      display: block;
      padding: 0;
      margin: 0;
    }
  }

  .octo-icontitle {
    flex: 1 1 auto;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    width: 90%;

    &.octo-title-space {
      margin-bottom: 6px;
    }

    .octo-icon {
      font-size: 16px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }

    .octo-titletext {
      padding-top: 2px;
      padding-bottom: 2px;
      display: -webkit-box; // stylelint-disable-line
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 100px;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical; // stylelint-disable-line
    }
  }

  .IconButton {
    border-radius: 3px;
    margin-right: 6px;
    padding: 0;
    background: rgb(var(--center-channel-color-rgb), 0.1);
    box-shadow:
      rgba(var(--center-channel-color-rgb), 0.1) 0 0 0 1px,
      rgba(var(--center-channel-color-rgb), 0.1) 0 2px 4px;
    height: 24px;
    width: 24px;
  }
}
